import "moment/locale/ru";
import "moment/locale/uz";

import * as audiosActions from "../actions/audiosActions";
import * as commonActions from "../actions/commonActions";
import * as radioActions from "../actions/radioActions";
import * as searchActions from "../actions/searchActions";
import * as userActions from "../actions/userActions";

import { Content, Header, Layout, Sidebar } from "../components/Layout";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { ThemeProvider, createGlobalStyle } from "styled-components";

import ErrorBoundary from "../hoc/ErrorBoundary";
import PropTypes from "prop-types";
import React from "react";
import asyncComponent from "../hoc/asyncComponent";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { i18nActions } from "redux-react-i18n";
import { routerActions } from "connected-react-router";
import moment from "moment";
import AdFoxBanner from "../components/Ads/AdFoxBanner";
import isUzb from "../utils/checkCountry";
import AdBlockPage from "./AdBlockPage";
import "blockadblock";
import "react-confirm-alert/src/react-confirm-alert.css";

// Async imports
const NotFoundPage = asyncComponent(() => import("./NotFoundPage"));
const TestRoute = asyncComponent(() => import("./TestRoute"));
const HomePage = asyncComponent(() => import("./HomePage"));
const AppsPage = asyncComponent(() => import("./AppsPage"));
const AdvertisePage = asyncComponent(() => import("./AdvertisePage"));
const PromoCodePage = asyncComponent(() => import("./PromoCodePage"));
const LicensesPage = asyncComponent(() => import("./LicensesPage"));
const SubscriptionsPage = asyncComponent(() => import("./SubscriptionsPage"));
const ContactsPage = asyncComponent(() => import("./ContactsPage"));
const ArticlePage = asyncComponent(() => import("./ArticlePage"));
const DeleteAccountInfoPage = asyncComponent(() =>
  import("./DeleteAccountInfoPage")
);
const PrivacyArticlePage = asyncComponent(() => import("./PrivacyArticlePage"));
const TermsArticlePage = asyncComponent(() => import("./TermsArticlePage"));
const ActorPage = asyncComponent(() => import("./ActorPage/ActorPage"));
const SearchPage = asyncComponent(() => import("./SearchPage"));
const ConfirmEmail = asyncComponent(() => import("./ConfirmEmail"));
const ConfirmPassword = asyncComponent(() => import("./ConfirmPassword"));
const AuthPage = asyncComponent(() => import("./AuthPage/AuthPage"));
const ProfilePage = asyncComponent(() => import("./Profile/ProfilePage"));
const TvPage = asyncComponent(() => import("./TV/TvPage"));
const TvDetailsPage = asyncComponent(() => import("./TV/TvDetailsPage"));
const GuidePage = asyncComponent(() => import("./Guide/GuidePage"));
const RadioPage = asyncComponent(() => import("./Radio/RadioPage"));
const MoviesPage = asyncComponent(() => import("./Movies/MoviesPage"));
const SerialsPage = asyncComponent(() => import("./Serials/SerialsPage"));
const PodcastsPage = asyncComponent(() => import("./Podcasts/PodcastsPage"));
const AudiobooksPage = asyncComponent(() =>
  import("./Audiobooks/AudiobooksPage")
);
const CartoonsPage = asyncComponent(() => import("./Cartoons/CartoonsPage"));
const ServicesPage = asyncComponent(() => import("./Profile/ServicesPage"));
// const MusicPage = asyncComponent(() => import('./Music/MusicPage'));
// const ClipsPage = asyncComponent(() => import('./Clips/ClipsPage'));

const GlobalStyle = createGlobalStyle`
  html { background-color: #272727; scroll-behavior: smooth; }
  #adfox_153804163293983007 {
    & > div {
      margin: 0 auto;
    }
  }
  body {
    position: relative;
    margin: 0;
    padding: 0;
    font-family: 'Museo Sans Regular', Helvetica, Calibri, sans-serif;

    /* With React js for detect touch as click on iOS devices
     * Need use cursor: pointer
     */
    cursor:
      ${
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
          ? "pointer"
          : "default"
      };

      /* width */
      ::-webkit-scrollbar {
          width: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
          background: rgba(69,79,87,0.5);
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
          background: #777;
      }
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  #modal {
    position: relative;
    z-index: 999;
  }

  .react-confirm-alert-overlay {
    background: rgba(0, 0, 0, .9) !important;
    color: #fff !important;
    padding: 30px;
  }

`;

class App extends React.Component {
  static propTypes = {
    // Actions
    routerActions: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    i18n: PropTypes.shape({
      setCurrentLanguage: PropTypes.func.isRequired,
    }).isRequired,
    commonActions: PropTypes.shape({
      sidebarCollapsed: PropTypes.func,
      forceSidebarCollapse: PropTypes.func,
      fcmRegisterToken: PropTypes.func,
      reciveNotification: PropTypes.func,
    }).isRequired,
    userActions: PropTypes.shape({
      getInitialUserInfo: PropTypes.func.isRequired,
      userSignIn: PropTypes.func.isRequired,
      userSignOut: PropTypes.func.isRequired,
    }).isRequired,
    searchActions: PropTypes.shape({
      getSearchData: PropTypes.func,
      resetSearchData: PropTypes.func,
    }).isRequired,
    audiosActions: PropTypes.shape({
      clearAudios: PropTypes.func,
    }).isRequired,
    radioActions: PropTypes.shape({
      updateSelectedIndex: PropTypes.func,
      clearRadioPlayer: PropTypes.func,
    }).isRequired,

    // Data
    currentLanguage: PropTypes.string.isRequired,
    location: PropTypes.shape({
      state: PropTypes.object,
    }).isRequired,
    commonState: PropTypes.shape({
      sidebarCollapsed: PropTypes.bool,
      notifications: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    userData: PropTypes.shape({
      isAuthorized: PropTypes.bool,
      profileData: PropTypes.shape({
        id: PropTypes.number,
        email: PropTypes.string,
        name: PropTypes.string,
        balance: PropTypes.string,
      }),
    }).isRequired,
    audios: PropTypes.shape({
      status: PropTypes.number,
      data: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    radioPlayerData: PropTypes.shape({
      selectedIndex: PropTypes.number,
      channels: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      theme: "dark",
      sidebarCollapsed: this.props.commonState.sidebarCollapsed,
      adBlockEnabled: false,
      // messaging: typeof firebase === 'undefined' ? null : firebase.messaging(),
    };

    // Refs
    this.header = React.createRef();
    this.sidebar = React.createRef();

    this.sidebarToggleHandler = this.sidebarToggleHandler.bind(this);
    this.sidebarSectionClickHandler =
      this.sidebarSectionClickHandler.bind(this);
    this.switchLangHandler = this.switchLangHandler.bind(this);
    // this.resizeHandler = this.resizeHandler.bind(this);
    this.sidebarOutsideClickHandler =
      this.sidebarOutsideClickHandler.bind(this);
  }

  componentDidMount() {
    // window.addEventListener('resize', this.resizeHandler);
    document.addEventListener("click", this.sidebarOutsideClickHandler);

    moment.locale(this.props.currentLanguage);

    this.props.userActions.getInitialUserInfo();

    // Get user info every 15 minutes for manage multiple user from 1 account
    setInterval(() => {
      this.props.userActions.getInitialUserInfo();
    }, 15 * 60 * 1000);

    if (window.innerWidth > 1300) {
      this.props.commonActions.sidebarCollapsed(false);
    } else {
      this.props.commonActions.sidebarCollapsed(true);
    }
    // eslint-disable-next-line
    blockAdBlock.onDetected(() => {
      this.setState({ adBlockEnabled: true });
    });

    if (isUzb()) {
      const ym = document.createElement("script");
      ym.setAttribute("src", "/ya-metrika-uz.js");
      document.head.appendChild(ym);
    } else {
      const ym = document.createElement("script");
      ym.setAttribute("src", "/ya-metrika-tv.js");
      document.head.appendChild(ym);
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('resize', this.resizeHandler);
    document.removeEventListener("click", this.sidebarOutsideClickHandler);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { commonState } = nextProps;

    if (commonState.sidebarCollapsed !== prevState.sidebarCollapsed) {
      return {
        sidebarCollapsed: commonState.sidebarCollapsed,
      };
    }

    return null;
  }

  sidebarToggleHandler() {
    this.props.commonActions.sidebarCollapsed(!this.state.sidebarCollapsed);
  }

  sidebarOutsideClickHandler(e) {
    // ignore clicks on the component itself
    if (!this.state.sidebarCollapsed && window.innerWidth < 1300) {
      if (
        !this.sidebar.current.container.current.contains(e.target) &&
        !this.header.current.container.current.contains(e.target)
      ) {
        this.props.commonActions.sidebarCollapsed(!this.state.sidebarCollapsed);
      }
    }
  }

  sidebarSectionClickHandler() {
    if (window.innerWidth < 1300) {
      this.props.commonActions.sidebarCollapsed(true);
    }
  }

  switchLangHandler(lang) {
    this.props.i18n.setCurrentLanguage(lang);
    moment.locale(lang);
    localStorage.setItem("lang", lang);
  }

  render() {
    const { adBlockEnabled } = this.state;

    return (
      <ThemeProvider theme={{ mode: this.state.theme }}>
        <React.Fragment>
          <GlobalStyle />
          <ErrorBoundary>
            {adBlockEnabled ? (
              <AdBlockPage />
            ) : (
              <Layout sidebarCollapsed={this.state.sidebarCollapsed}>
                <Header
                  theme={this.state.theme}
                  sidebarToggleHandler={this.sidebarToggleHandler}
                  ref={this.header}
                  userData={this.props.userData}
                  themeBtnHandler={this.themeBtnHandler}
                  getSearchData={this.props.searchActions.getSearchData}
                  resetSearchData={this.props.searchActions.resetSearchData}
                  clearAudios={this.props.audiosActions.clearAudios}
                  routerActions={this.props.routerActions}
                  location={this.props.location}
                  userSignOut={this.props.userActions.userSignOut}
                  audios={this.props.audios}
                  radioPlayerData={this.props.radioPlayerData}
                  notifications={this.props.commonState.notifications}
                  radioActions={this.props.radioActions}
                />
                <div>
                  <Sidebar
                    ref={this.sidebar}
                    collapsed={this.state.sidebarCollapsed}
                    switchLangHandler={this.switchLangHandler}
                    sidebarSectionClickHandler={this.sidebarSectionClickHandler}
                  />
                  <Content sidebarCollapsed={this.state.sidebarCollapsed}>
                    <AdFoxBanner slot={4} />
                    <Switch>
                      <Redirect from="/site/listchannels" to="/tv" />
                      <Redirect from="/users/signup" to="/auth" />
                      <Redirect from="/users/remind" to="/auth/forgot" />

                      <Route exact path="/" component={HomePage} />
                      <Route exact path="/test" component={TestRoute} />
                      <Route path="/auth" component={AuthPage} />
                      <Route path="/profile" component={ProfilePage} />
                      <Route path="/activate" component={PromoCodePage} />
                      <Route
                        exact
                        path="/users/confirm/:hash"
                        component={ConfirmEmail}
                      />
                      <Route
                        exact
                        path="/users/validate/:hash"
                        component={ConfirmPassword}
                      />
                      <Route path="/apps" component={AppsPage} />
                      <Route path="/ad" component={AdvertisePage} />
                      <Route path="/licenses" component={LicensesPage} />
                      <Route
                        exact
                        path="/vouchers"
                        component={SubscriptionsPage}
                      />
                      <Route path="/contacts" component={ContactsPage} />
                      <Route path="/article/:id" component={ArticlePage} />
                      <Route
                        path="/delete-account"
                        component={DeleteAccountInfoPage}
                      />
                      <Route path="/privacy" component={PrivacyArticlePage} />
                      <Route path="/terms" component={TermsArticlePage} />
                      <Route path="/actor/:id" component={ActorPage} />
                      <Route path="/search" component={SearchPage} />
                      <Route exact path="/tv" component={TvPage} />
                      <Route path="/tv/:id/:title" component={TvDetailsPage} />
                      <Route path="/guide/:id" component={GuidePage} />
                      <Route exact path="/radio" component={RadioPage} />
                      <Route path="/movies" component={MoviesPage} />
                      <Route path="/serials" component={SerialsPage} />
                      <Route path="/podcasts" component={PodcastsPage} />
                      <Route path="/cartoons" component={CartoonsPage} />
                      {/* <Route path="/clips" component={ClipsPage} /> */}
                      <Route path="/audiobooks" component={AudiobooksPage} />
                      <Route
                        exact
                        path="/vouchers/all"
                        render={() => (
                          <div style={{ margin: 30 }}>
                            <ServicesPage />
                          </div>
                        )}
                      />
                      {/* <Route path="/music" component={MusicPage} /> */}
                      <Route component={NotFoundPage} />
                    </Switch>
                    {isUzb() && (
                      <a
                        href="https://www.uz/rus/toprating/cmd/stat/id/27963"
                        target="_top"
                        style={{ display: "none" }}
                      >
                        <img
                          alt="Топ рейтинг www.uz"
                          src="https://www.uz/plugins/top_rating/count/cnt.png?id=27963&amp;r=&amp;pg=http%3A//mediabay.uz/&amp;c=Y&amp;j=N&amp;wh=1366x768&amp;px=24&amp;js=1.3&amp;col=D0D0CF&amp;t=ffffff&amp;p=24211D"
                          style={{ height: 31, width: 81 }}
                        />
                      </a>
                    )}
                  </Content>
                </div>
              </Layout>
            )}
          </ErrorBoundary>
        </React.Fragment>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.i18n.currentLanguage,
    commonState: state.common,
    userData: state.user,
    contentDetails: state.contentDetails,
    audios: state.audios,
    radioPlayerData: state.radio.player,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    i18n: bindActionCreators(i18nActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    searchActions: bindActionCreators(searchActions, dispatch),
    audiosActions: bindActionCreators(audiosActions, dispatch),
    radioActions: bindActionCreators(radioActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
